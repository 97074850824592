import React, { useRef } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import swal from 'sweetalert'
import { AwesomeButton } from 'react-awesome-button'
import 'react-awesome-button/dist/styles.css'

import Layout from '../../components/Layout'
import Head from '../../components/Head'
import './styles.scss'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactPage = () => {
  const { register, handleSubmit, errors } = useForm()
  const contactForm = useRef(null)

  const onSubmit = data => {
    // post data using fetch to submit data properly
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data)
    })
      .then(() =>
        swal({
          title: 'Message Sent!',
          text: "I'll get in touch very soon!",
          icon: 'success',
          button: 'Go Home'
        }).then(goToHomePage => {
          if (goToHomePage) {
            navigate('/')
          } else {
            contactForm.current.reset()
          }
        })
      )
      .catch(error =>
        swal({
          title: 'Oops!',
          text: 'Something went wrong. Can you please try again?',
          icon: 'error'
        })
      )
  }

  return (
    <Layout isNavVisible={true}>
      <Head title="Reach Me" />
      <div id="contactPage" className="d-flex flex-grow-1">
        <div className="row no-gutters flex-grow-1 align-items-start">
          <div className="col-12 mb-3">
            <h1>How can I help you today?</h1>
          </div>
          <div className="col-12 col-md-10 col-lg-7 col-xl-5">
            <div className="col-md-12 left-panel p-0">
              <form
                className="contact-form"
                data-netlify="true"
                ref={contactForm}
                name="contactForm"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  type="hidden"
                  name="form-name"
                  ref={register()}
                  value="contactForm"
                />
                <div className="form-row mx-0 mb-2">
                  <div className="col pos-rel p-0 mr-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="clientName"
                      ref={register({ required: true })}
                    />

                    <div className="focus-highlight"></div>
                    {errors.clientName && (
                      <span className="text-danger">
                        Knowing your name would be very helpful.
                      </span>
                    )}
                  </div>
                  <div className="col pos-rel p-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="clientEmail"
                      ref={register({
                        required: true,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      })}
                    />

                    <div className="focus-highlight"></div>
                    {errors.clientEmail &&
                      errors.clientEmail.type === 'required' && (
                        <span className="text-danger">
                          I kinda need your email to contact you, if it's
                          alright.
                        </span>
                      )}

                    {errors.clientEmail &&
                      errors.clientEmail.type === 'pattern' && (
                        <span className="text-danger">
                          Looks like this email is invalid
                        </span>
                      )}
                  </div>
                </div>
                <div className="form-group mb-2 p-0 pos-rel">
                  <input
                    type="text"
                    className="form-control mr-2"
                    placeholder="What's this about?"
                    name="clientSubject"
                    ref={register({ required: true })}
                  />
                  <div className="focus-highlight"></div>
                  {errors.clientSubject && (
                    <span className="text-danger">
                      A brief subject is required
                    </span>
                  )}
                </div>
                <div className="form-group mb-5 p-0 pos-rel">
                  <textarea
                    className="form-control"
                    id="projectDetails"
                    placeholder="Okay. I'm all ears now."
                    rows="5"
                    name="clientMessage"
                    ref={register({ required: true })}
                  />
                  <div className="focus-highlight"></div>
                  {errors.clientMessage && (
                    <span className="text-danger">
                      I might need a brief explanation/description
                    </span>
                  )}
                </div>
                <AwesomeButton
                  style={{ fontWeight: 500 }}
                  type="secondary"
                  ripple
                >
                  Send Message
                </AwesomeButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
